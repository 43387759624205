// .PropertyEnquiryForm span {
//   color: white;
// }

.property-enquiry-collapse {
  border-radius: 0.5rem;
  .property-enquiry-panel {
    &:last-child {
      border-radius: 0.5rem;
      overflow: hidden;
      .ant-collapse-header {
        border-radius: 0.5rem 0.5rem 0 0;
      }
    }
    .ant-card-body {
      max-height: 60vh;
      overflow-y: auto;
      padding-bottom: 0;
    }
    .ant-collapse-header {
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      word-break: break-all;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 12px 40px 12px 40px;
    }
    input,
    .ant-select-selection {
      border-radius: 999px;
    }
    textarea {
      border-radius: 15px;
    }
  }
}
