.property-time {
  .time-collapse > div:last-child,
  .time-collapse > div:last-child > div:first-child {
    border-radius: 0.5em;
  }
  .ant-collapse.time-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 0;
  }
  .times-cont {
    max-height: 60vh;
    @media screen and (max-width: 768px) {
      max-height: none;
    }
  }
}
