.SaveButton {
  display: inline-flex;
  align-items: center;

  &.ant-btn-primary {
    svg {
      .path {
        stroke: white;
      }
    }
  }

  &.failed {
    background: #ec5659;
    border-color: #d14649;
  }

  &.success {
    background: #1f9d55;
    border-color: rgb(26, 139, 75);
  }

  svg {
    margin: 0 0.5em 0 0;
  }

  svg.times,
  svg.check {
    width: 20px;
    max-width: 0px;
    display: inline-block;
    margin: 0;
  }

  svg.times.failed,
  svg.check.success {
    margin: 0 0.5em 0 0;
    max-width: 20px;
  }

  .success .path,
  .failed .path {
    stroke-width: 0.75em;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;

    &.circle {
      -webkit-animation: dash 0.9s ease-in-out infinite;
      animation: dash 0.9s ease-in-out infinite;
    }

    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 0.9s 0.35s ease-in-out forwards infinite;
      animation: dash 0.9s 0.35s ease-in-out forwards infinite;
    }

    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards infinite;
      animation: dash-check 0.9s 0.35s ease-in-out forwards infinite;
    }
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}
