.PropertyNearbySchoolsList {
  .search-results .ant-list-item {
    .ant-list-item-content {
      width: 100%;
    }
  }
  .ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-tab {
        font-weight: bold;
      }
    }
    .ant-tabs-ink-bar {
      height: 4px;
      border-radius: 99px;
    }
  }
}
