.PropertyPage {
  * {
    transition: all ease-in 0.3s;
  }

  .enquiry-form.light > form > div.PropertyEnquiryForm span {
    margin-left: 0;
    color: #22292f;
    font-weight: 700;

    > span {
      color: #3d4852;
    }
  }

  .enquiry-form.dark > form > div.PropertyEnquiryForm span {
    margin-left: 0;
    color: white;
    font-weight: 800;

    > span {
      color: #dae1e7;
    }
  }

  .text-overflow {
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .property-map {
    transition: none;

    *:not(.ant-btn) {
      transition: none;
    }
  }

  .property-description-wrapper {
    position: relative;
    padding-bottom: 10px;

    .description-toggle {
      position: absolute;
      z-index: 40;
      bottom: 10px;
      height: 100%; // margin-top: -5px;
      width: 100%;
      padding-bottom: 10px;
      background: rgba(255, 255, 255, 0);
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.82) 86%,
        rgba(255, 255, 255, 0.91) 100%
      );

      span {
        position: relative;
        display: inline-block;
        top: 100%;
      }
    }

    &.description-readmore {
      padding-bottom: 60px;

      .description-toggle {
        height: auto;
      }
    }
  }

  .property-enquiry-panel {
    &.ant-collapse-item-active {
      > div:first-child {
        i.arrow {
          display: none;
        }
      }
    }
  }

  .property-time {
    > div > div.ant-collapse-item-active {
      > div:first-child {
        i {
          display: none;
        }
      }
    }
  }

  .back-btn {
    display: inline-block;
    opacity: 0.35;
    color: white;
    background: grey;
    font-weight: bold;

    &:hover {
      opacity: 1;
    }
  }

  .love-heart > div {
    margin-top: 0;
  }

  .property-main-features {
    div:last-child {
      border: none;
    }
  }

  @media only screen and (max-width: 576px) {
    .property-address,
    .property-action {
      margin-left: 0rem;
      margin-right: 0rem;
      text-align: center;
    }

    .property-action button.save {
      width: 60%;
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 510px) {
    .property-address {
      margin-left: 0rem;
      margin-right: 1rem;
      text-align: center;
    }
  }

  @media only screen and (max-width: 360px) {
    .property-content p.text-base {
      padding: 0 10px;
    }
  }
}
