.contact-agent-page {
  .step-title {
    font-size: theme('fontSize.2xl');
    color: theme('colors.white');
    margin-bottom: theme('margin.6');
  }
  .box {
    transition: box-shadow 0.3s, transform 0.4s;
    width: 150px;
    height: 150px;
    margin: 20px;
    border-radius: 5px;
    border: 3px solid theme('colors.blue.500');
    background: #fff;
    &:hover {
      box-shadow: 0 0 11px theme('colors.blue.500');
      cursor: pointer;
      transform: scale(1.2);
    }
  }
  .rectangle {
    transition: box-shadow 0.3s, transform 0.4s;
    width: 250px;
    height: 50px;
    margin: theme('margin.5');
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #fff;
    &:hover {
      box-shadow: 0 0 11px theme('colors.blue.500');
      cursor: pointer;
      transform: scale(1.2);
    }
  }
  .support-card {
    background-color: rgba(0, 0, 0, 0.2);
    color: theme('colors.white');
    font-weight: theme('fontWeight.bold');
    padding: theme('padding.4');
  }
  .ant-progress {
    &-bg {
      background-color: theme('colors.blue.500');
    }
    &-text {
      color: theme('colors.white');
    }
  }
}
