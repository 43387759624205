.property-enquiry-card {
  .ant-card-head {
    background-color: #3b82f6;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
}

form {
  .monthly-payment-card {
    background-color: #f1f5f8;
    border-radius: 0.5em;
    float: right;
    width: 50%;
  }
}

.ant-input-number-affix-wrapper {
  width: 100% !important;
}
