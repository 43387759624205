.AboutPage {
  .agency-banner {
    background: linear-gradient(270deg, #e6faff, #f5f5f5);
    background-size: cover;
    background-position: center center;
    position: relative;

    &:after {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      content: '';
    }
    .actions {
      li {
        float: left;
      }
    }
  }
}
