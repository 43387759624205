.AgencyEnquiryForm {
  > div > div {
    background: rgb(245, 247, 248);
    padding: 20px;
    border-radius: 0.75rem;
  }
  .choices {
    height: 50px;
    line-height: 50px;
    border-radius: 0.75rem;
    font-weight: normal;
    margin-bottom: 8px;
    &:before {
      display: none;
    }
    &:hover,
    &.ant-radio-button-wrapper-checked {
      color: #22292f;
      font-weight: bold;
      box-shadow: 0px 0px 8px 0 rgba(141, 141, 141, 0.333);
    }
  }
  .choices,
  input {
    border: 1px solid #dae1e7;
    border-left: 1px solid #dae1e7;
  }
  button {
    border: 0;
  }
}
