// .iconsvg-container {
//     overflow: hin;
//     position: absolute;
//     width: 100%;
//     top: -40%;
//     left: -10%;
//     z-index: -1;
#iconsvg {
  path {
    &#house {
      stroke-dasharray: 65.496;
      stroke-dashoffset: 65.496;
      animation: house 3s linear alternate infinite;
    }
    &#teardrop {
      stroke-dasharray: 63.436;
      stroke-dashoffset: 63.436;
      animation: teardrop 3s linear alternate infinite;
    }
  }
  ellipse {
    &#shadow {
      animation: fadeInOut 3s linear alternate infinite;
    }
  }
  g {
    &#window {
      animation: fadeInOut 3s linear alternate infinite;
    }
  }
}

@keyframes house {
  0% {
    opacity: 0;
    stroke-dashoffset: 65.496;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes teardrop {
  0% {
    opacity: 0;
    stroke-dashoffset: 63.436;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
