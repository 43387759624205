.property-modal-wrapper {
  padding: 0;
  .property-photos.property-photos-modal.ant-modal {
    @media (max-width: 767px) {
      max-width: calc(100vw - 1px);
      //padding: 10px 20px;
    }
  }
  .property-photos.property-photos-modal {
    height: 100%;
    top: 0%;
    background: transparent;
    overflow: hidden;
    padding: 0;
    margin: 0;
    > div {
      background: transparent;
      height: 100%;
      box-shadow: none;
    }
    .ant-modal-close {
      &-x {
        color: white;
        font-size: 25px;
        filter: drop-shadow(1px 1px 2px black);
        -webkit-filter: drop-shadow(1px 1px 2px black);
        &:focus {
          outline: 0;
        }
      }
    }
    .ant-modal-body {
      height: 100%; // background: transparent;
      padding: 0;
      .ant-carousel {
        background: transparent;
        &:first-child {
          height: 90%;

          @media screen and (max-width: 576px) {
            height: 80%;
          }
        }
        &:nth-child(2) {
          height: 10%;
        }
      }
      .property-modal-carousel {
        .ls-blur-up-img,
        .property-image {
          position: relative;
          width: auto;
          max-height: 100%;
          display: block;
          min-width: auto;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          height: 100%;
          @media screen and (max-width: 992px) {
            height: auto;
            width: 100%;
          }
          /* only if you want to change the blur-up option from auto to always */
          //font-family: "blur-up: always", "object-fit: cover";
          //object-fit: cover;
        }
        height: 100%;
        padding: 10px 0px;
        .arrow {
          z-index: 50;
          color: white;
          height: 40px;
        }
        .arrow.next {
          right: 20px;
        }
        .arrow.prev {
          left: 20px;
        }
        .property-enquiry .enquiry-button {
          display: block;
          height: 40px;
        }
        .property-enquiry .enquiry-button * {
          height: auto;
        }
        .property-modal-carousel-item {
          height: auto;
        }
        *:not(.slick-list):not(.slick-dots):not(img):not(.property-enquiry):not(.carousel-pagination):not(li):not(i):not(svg) {
          text-align: center;
          height: 100%;
        }
        .slick-list {
          text-align: center;
          height: 100%; // padding: 20px;
        } //img {
        //    display: block;
        //    position: relative;
        //    top: 50%;
        //    left: 50%;
        //    max-height: 100%;
        //    min-width: auto;
        //    -webkit-transform: translate(-50%, -50%);
        //    -ms-transform: translate(-50%, -50%);
        //    transform: translate(-50%, -50%);
        //}
        img.thumb {
          position: absolute;
          min-width: auto;
          height: 100%;
          filter: blur(60px);
        }
      }
    }
    .property-photos-control {
      position: absolute; // width: 100%;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      > button {
        background: transparent;
        border: none;
        color: white;
        font-size: 40px;
        padding: 0px 30px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.property-modal-nav {
  position: absolute;
  height: 100%;
  padding: 10px 0px;
  *:not(.slick-list):not(.slick-dots):not(img):not(.property-enquiry):not(.carousel-pagination):not(li):not(i):not(svg) {
    text-align: center;
    height: 100%;
  }
  .slick-list {
    text-align: center;
    height: 100%;
    .slick-track {
      display: inline-block;
    }
    .slick-slide {
      width: 40px;
      .property-modal-carousel-item {
        height: auto;
        padding: 4px 5px;
      }
      &:not(.slick-current) {
        filter: grayscale(100%);
      }
      .carousel-pagination {
        position: relative;
        height: 100%;
        i {
          color: white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @media screen and (max-width: 768px) {
            height: 2rem;
            width: 2rem;
            font-size: 25px;
          }
          font-size: 45px;
        }
      }
      &.slick-active i {
        color: #3b82f6;
      }
      img {
        width: 95%;
        object-fit: cover;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 100%;
        min-width: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
  .progressive-loading-wrapper {
    position: relative;
  }
  img.thumb {
    position: absolute;
  }
}

body .ant-modal-mask {
  filter: opacity(1);
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-filter: opacity(1);
}

.center-align {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
