footer {
  background-color: pink;
  a {
    border-bottom: 1px solid rgb(125, 158, 27);
  }
}

.footer {
  position: relative;
  margin-top: theme('margin.12');
  z-index: 10;
  .ant-list-split {
    .footer-list,
    .quick-links {
      border: none;
    }
  }
  .hero-bg {
    opacity: 0.05;
  }
  .crm-list {
    box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 768px) {
  .footer-list {
    > div > div:first-child {
      margin-right: 0;
    }
    //.avatar {
    //  display: none;
    //}
  }
}

#root .footer {
  padding: 0;
}
