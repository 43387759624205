//.intersection-obs {
//  height: 100%;
.progressive-loading {
  overflow: hidden;
  position: relative;
  height: 100%;
  &-wrapper {
    img {
      min-width: 100%;
      transform: scale(1.1);
      opacity: 1;
      transition: opacity 2s ease-in-out;
      &.original {
        position: absolute;
      }
      &.thumb {
        filter: blur(8px);
      }
      &.hide {
        opacity: 0;
      }
    }
  }
}

//}
