.LoadingDots.ant-spin-dot {
  width: 60px;
}

.LoadingDots {
  width: 100%;
  text-align: center;

  .ant-spin-dot {
    width: 60px;
  }
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #3b82f6;
    margin-right: 10px;
    opacity: 0;

    &-1 {
      animation: loading 1s 0s linear alternate infinite;
    }

    &-2 {
      animation: loading 1s 0.3s linear alternate infinite;
    }

    &-3 {
      animation: loading 1s 0.6s linear alternate infinite;
      margin-right: 0;
    }
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  90% {
    opacity: 0;
  }
}
